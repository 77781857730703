import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { isMobileOnly } from "react-device-detect";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Image from "../../ImageQuerys/RandomImages";
import FullQuoteStyles from "./FullQuoteStyles";
import FullQuoteBg from "./FullQuoteBg";

const quoteImg = "quoteImg.png";

const container = {
  show: {
    transition: {
      staggerChildren: 0.35,
    },
  },
};
const item = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 1,
      delay: 1,
    },
    exit: {
      opacity: 0,
      y: -200,
      transition: {
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  },
};
const FullQuote = (props) => {
  const { sectionData, bgDark } = props;
  const controls = useAnimation();
  const titleControls = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("show");
    }
    if (!inView) {
      controls.start("exit");
    }
  }, [controls, inView, titleControls]);
  return (
    <section
      className={`full-quote w-full overflow-hidden border-box ${
        bgDark ? "bg-black" : "bg-white"
      }`}
    >
      <FullQuoteBg
        imageName={sectionData.bg}
        className="overflow-hidden border-box"
      >
        <div className="screen" />
        <div
          className="relative max-w-1440 lg:mx-auto overflow-hidden py-[147px] lg:py-0"
          ref={ref}
        >
          <Image
            imageName={quoteImg}
            className="relative w-[117px] top-auto left-auto mx-auto lg:top-32 z-10 overflow-hidden"
          />
          <motion.div
            className="quote-content max-w-[927px] mx-8 lg:mx-auto pt-8 lg:pt-[268px] "
            variants={container}
            initial={isMobileOnly ? "show" : "hidden"}
            animate={controls}
          >
            <motion.h3
              variants={item}
              className="text-white text-h3-small lg:text-h2 leading-[160%] text-center mb-10"
            >
              {sectionData.quote}
            </motion.h3>
            <motion.div
              className="flex flex-col justify-center items-center pb-0 lg:pb-[268px]"
              variants={item}
            >
              <div className="w-[72px] h-[72px] rounded-full mb-2 mt-0 overflow-hidden table border-[3px] border-white border-solid z-1">
                <Image
                  imageName={sectionData.person.img}
                  className="w-[72px] h-[72px] rounded-full z-0"
                  style={{ borderRadius: 100 }}
                />
              </div>
              <h4 className="text-[15px] text-white font-bold leading-[130%] mb-1">
                {sectionData.person.name}
              </h4>
              <h5 className="text-[11px] text-white text-center leading-[130%]">
                {sectionData.person.shop}
              </h5>
            </motion.div>
          </motion.div>
        </div>
      </FullQuoteBg>
      <FullQuoteStyles />
    </section>
  );
};

FullQuote.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
  bgDark: PropTypes.bool,
};
FullQuote.defaultProps = {
  sectionData: {},
  bgDark: true,
};

export default FullQuote;
