import React from "react";
import { viewports, colors } from "../../../style-vars";

const { smOrSmaller, mdOrSmaller } = viewports;

const FullQuoteStyles = () => (
  <style jsx>{`
    .full-quote {
      position: relative;
      overflow: hidden;
    }
    .screen {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      top: 0;
      left: 0;
    }
  `}</style>
);

export default FullQuoteStyles;
